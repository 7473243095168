.header {
    text-align: center;
}

.faded {
    opacity: 0.1;
}

.card {
    font-weight: bold;
    font-size: 34px;
    text-align: center;
}

.rantstack {
    overflow: auto;
    max-height: 600px;
    align-items: center;
}

.toast-header > img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}